jQuery(function($) {

	$( '.young-feeling-button' ).on( 'click', function( e ) {

		e.preventDefault();

		var button_id = this.id;

		$( '.young-feeling-button' ).removeClass( 'is-active' );

		$( '.young-feeling-content' ).css( 'display', 'none' );

		$( '#' + button_id ).toggleClass( 'is-active' );

		$( '#' + button_id + '-content' ).css( 'display', 'flex' );

		if ( $( window ).width() < 576 ) {

			if ( ! $('body').hasClass('admin-bar') ) {

				var scrollDistance = $( '#' + button_id + '-content' ).offset().top - 95;

			} else {

				var scrollDistance = $( '#' + button_id + '-content' ).offset().top - 141;

			}

			$( 'html, body' ).animate( {

		    scrollTop: scrollDistance

			}, 400 );

		}

	} );

});
