

// Bind event listener for a click to the document
document.addEventListener( 'click', function ( event ) {
  detectClickOnNodeOutsideActiveItem( event.target );
} );

// Bind event listener for an escape keydown to the document
document.addEventListener( 'keydown', function ( event ) {
  if ( event.key === 'Escape' ) {

    // If the currently selected item is a submenu link
    if ( document.activeElement.classList.contains( 'desktop-submenu-link' ) ) {

      // Focus the parent menu item
      document.activeElement.parentNode.parentNode.parentNode.querySelector( 'a' ).focus();

    }

    // Close any open desktop submenus
    closeOpenDesktopSubmenu();

  }
} );

// Function to detect a click outside an active menu item or search form
function detectClickOnNodeOutsideActiveItem( clickedNode ) {
  // Try to get an active menu item
  let activeDesktopSubmenuItemLink = document.querySelector(
    '.desktop-menu-link.has-children[aria-expanded=true]'
  );

  // If there's an active submenu
  if ( activeDesktopSubmenuItemLink ) {
    // If the click is anywhere outside the original menu item
    if ( ! activeDesktopSubmenuItemLink.parentNode.contains( clickedNode ) ) {
      // Close the active submenu
      closeOpenDesktopSubmenu();
    }
  }
}

// Function to close an active submenu
function closeOpenDesktopSubmenu() {
  // Try to get an active menu item
  let activeDesktopSubmenuItemLink = document.querySelector(
    '.desktop-menu-link.has-children[aria-expanded=true]'
  );

  // If there is an active submenu
  if ( activeDesktopSubmenuItemLink ) {
    // Set the aria-expanded attribute to false
    // The menu visibility is controlled by this attribute in CSS
    activeDesktopSubmenuItemLink.setAttribute( 'aria-expanded', 'false' );

    // Update the aria label
    activeDesktopSubmenuItemLink.setAttribute(
      'aria-label',
      activeDesktopSubmenuItemLink
        .getAttribute( 'aria-label' )
        .replace( 'Deactivate', 'Activate' )
    );
  }
}

// Loop through all desktop menu links with children
document
  .querySelectorAll( '.desktop-menu-link.has-children' )
  .forEach( function ( desktopMenuItemLink ) {
    // Event listener - when this menu item link is clicked
    desktopMenuItemLink.addEventListener( 'click', function ( event ) {
      // Stop the link being followed and any propagation
      event.preventDefault();
      event.stopPropagation();

      // Store the clicked element as a variable
      let clickedDesktopMenuItemLink = event.currentTarget;

      // If the submenu isn't open yet
      if (
        clickedDesktopMenuItemLink.getAttribute( 'aria-expanded' ) === 'false'
      ) {

        // Close any open submenus
        closeOpenDesktopSubmenu();

        /* If this menu item is less than halfway across the screen
        if ( clickedDesktopMenuItemLink.getBoundingClientRect().x < ( window.innerWidth / 2 ) ) {

          // Align the submenu to the left
          if ( window.innerWidth >= 1200 ) {
            clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = '25px';
          } else {
            clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = '17px';
          }
          clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = 'auto';

          // Otherwise it's in the right half
        } else {

          // Align the submenu to the right
          clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = 'auto';
          if ( window.innerWidth >= 1200 ) {
            clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = '25px';
          } else {
            clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = '17px';
          }

        }
        */

        // Set the aria-expanded attribute to true
        // The menu visibility is controlled by this attribute in CSS
        clickedDesktopMenuItemLink.setAttribute( 'aria-expanded', 'true' );

        // Update the aria label
        clickedDesktopMenuItemLink.setAttribute(
          'aria-label',
          clickedDesktopMenuItemLink
            .getAttribute( 'aria-label' )
            .replace( 'Activate', 'Deactivate' )
        );

        // Otherwise the submenu is already open
      } else {
        // Close the open submenu
        closeOpenDesktopSubmenu();
      }
    } );
  } );

