jQuery(function($) {

	$('#mobile-menu-toggle').on( 'click', function() {

		$('#mobile-menu-toggle').toggleClass('is-active');

		$('#mobile-menu-wrapper').slideToggle( 200 );

	} );

});
