/*
 * Search toggles
 */


// On page load
jQuery(function($) {

	// Desktop search toggle
	$('#menu-search-toggle').on('click', function(){

		if ( ! $('#menu-search-form').hasClass('is-active') ) {

			var search_width = $('#header-menu-list').innerWidth() - $('#menu-search-toggle').outerWidth();

			$('#menu-search-form, #menu-search-toggle').addClass('is-active');

			$('#menu-search-form').animate(
				{ width: search_width },
				200,
				function() {
					$('#menu-search-text').focus();
				}
			);

		} else {

			$('#menu-search-form').animate(
				{ width: '0' },
				200,
				function() {
					$('#menu-search-text, #menu-search-toggle').blur();
					$('#menu-search-toggle, #menu-search-form').removeClass('is-active');
				}
			);

		}

	});

});
