

// Set up confirmation
let biro_anchor_icon_confirmation         = document.createElement( 'div' );
biro_anchor_icon_confirmation.textContent = 'Copied to clipboard';
biro_anchor_icon_confirmation.className   = 'anchor-icon-confirmation';
document.body.appendChild( biro_anchor_icon_confirmation );


// Anchor icon click
document.addEventListener( 'click', function( e ) {

  if ( e.target.classList.contains( 'anchor-icon' ) ) {

    // Init
    let link = window.location.protocol + "//" + window.location.host + window.location.pathname + '#' + e.target.parentNode.id;

    // Populate confirmation
    if ( navigator.clipboard ) {
      navigator.clipboard.writeText( link )
        .then(() => {
          biro_anchor_icon_confirmation.textContent = 'Copied to clipboard';
        })
        .catch((error) => {
          biro_anchor_icon_confirmation.textContent = 'Not copied!';
        });
    } else {
      biro_anchor_icon_confirmation.textContent = 'Not copied!';
    }

    // Show / hide confirmation
    biro_anchor_icon_confirmation.style.display = 'block';
    setTimeout( function() {
      biro_anchor_icon_confirmation.style.display = 'none';
    }, 3000 );

  }

});
